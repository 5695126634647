$black: #010103;
$grey: #1F2833;
$white:#C5C6C7;
$bright:#66FCF1;
$dull:#45A29E;
*{
    background-color:#010103;
}
.dump{
    color:$black;
}

.heroRight{
    height: 58vh;
    width: 80ch;
    border-radius: 10;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 10%;
    margin-left: 20%;
}

#heroLeft{
    background-color:#010103;
}

.heroRightColor{
    background-color:$bright;
    height: 90vh;
    box-sizing: border-box;
    overflow: hidden;
}


.hero-text-box{
    background-color: $black;
    padding-left: 30%;
}

.hero-text{
    color:#1F2833;
    font-size: 5vw;
    font-family: 'Bangers', cursive;
    white-space: nowrap;
}

.quote-container{
    margin-top: 0;
    background-color: #000103;
    padding-left: 5%;
    padding-top: 2%;
}
.quote{
    color: $white;
    font-family: 'Work Sans', sans-serif;
    font-size: 3em;
    font-weight:100;
    line-height: 120%;
    letter-spacing: -4px;
    text-align: left;
    border-left: 3px solid $bright;
    padding-left: 10px;
    &:before, &:after {
        content: '\201C';
        font-family: 'Sanchez';
        color: lighten($bright, 40%);
     }
     &:after {
        content: '\201D';
     }

}

.btn-container{
    padding-left: 12%;
    padding-bottom: 5%;
    
}

.btn{
    font-size: 1.5em;
    padding:1%;
    font-family: 'Work Sans', sans-serif;
    border: 4px solid $dull;
    margin-left: 10px;
}

.btn:hover, .btn:active {
    background-color: #45A29E;
    cursor: pointer;
 }
.subtitle-container{
    padding-top:2%;
    text-align: center;
    bottom: 0;
}
 .subtitle{
     color: $white;
     font-family: 'Work Sans', sans-serif;
     font-weight: 120;
     font-size: 2.2em;
 }

 .subtitle:hover {
     color: $bright;
    cursor: pointer;
 }


@media screen and (max-width: 600px) {
    .heroRight{
        width: 90%;
        margin-right:20 px;
    }
    .hero-text{
        white-space: normal;
        font-size: 2em;
    }
    .quote-container{
        padding-top: 2%;
    }
  }
  @media only screen and (min-width:600px) and (max-width:1600px){
    .quote-container{
        .quote{
            font-size: 2em;
        }
    }
    .dogimg{
        height: 70%;
    }
  }


@media screen and (max-width: 992px) {
    .heroRight{
        margin-left:10% ;
    }
    .hero-text-box{
        padding-left: 2px;
    }
  }