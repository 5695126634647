.logo-container {
  margin-left: 88%;
  padding-top: 0%;
}
.logo {
  font-family: "Bangers", cursive;
  color: #ec407a;
  font-size: 5em;
}
#f {
  color: #45a29e;
}
#f:hover {
  color: #ec407a;
}
.link {
  text-decoration: none;
  cursor: pointer;
}
.logo:hover,
.logo:active {
  color: #45a29e;
}
@media only screen and (min-width:600px) and (max-width:1600px){
  .logo{
    font-size: 3em;
  }
}
@media screen and (max-width: 600px) {
  .logo-container {
    margin-left: 75%;
  }
  .logo {
    font-size: 2em;
    margin-top: 0;
  }
}
