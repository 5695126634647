$black: #010103;
$grey: #1F2833;
$white:#C5C6C7;
$bright:#45A29E;
$dull:#45A29E;
$pink:#ec407a;
.root{
    text-align: center;
    width: 50%;
    margin-left: 25ch;
}

.rightGrid{
    margin-left: 10%;
}

.text{
    color: $dull;
    font-size: 1.2em;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-align: left;
}
.h11{
    font-size: 2.6em;
    padding-top: 0;
    
}
.h22{
    font-size: 2em;
}
.h11, .h22{
    color: $pink;
    font-family:  'Bangers', cursive;
    letter-spacing: 5px;
}

.check{
    background-color: $dull;
    z-index: 2;
}


 .bImg{
    width: 100%;
    -webkit-transition: width 1s;
  transition: width 1s;
}
.bImg:hover{
    cursor: pointer;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .sImg{
        size: 30%;
    }
    .root{
        width: 100%;
        margin-left: 0;
    }
}

