$black: #010103;
$grey: #1F2833;
$white:#C5C6C7;
$bright:#66FCF1;
$dull:#45A29E;
$pink:#ec407a;
.head{
    text-align: center;
    margin-top: 6%;
    padding-top: 0;
    font-size: 3em;
    font-family: 'Rock Salt', cursive;
    font-weight: 100;
    color: $pink;
    letter-spacing: 8px;
}

.head2{
    text-align: center;
    margin-top: 0;
    padding-top: 2%;
    font-size: 1.4em;
    font-weight: 400;
}

.ps{
    text-align: center;
    color: $pink;
}


@media screen and (max-width: 600px) {
    .number{
        font-size: 1.3em;
    }
    .head{
        font-size: 1.4em;
        letter-spacing: 4px;
    }
    .submit{
        margin-top: 4%;
    }
    .form{
        width: 25ch;
        margin-left: 0%;
        height: 30vh;
    }
   
}

.ps{
    font-size: 1.2em;
    font-family: 'Work Sans', sans-serif;
    color: $dull;
}
.form{
    border: none;
    height: 30vh;
    width: 50ch;
    
    
}

#form-container{
    display: flex;
    align-items: center;
    justify-content: center;
}