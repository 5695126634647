$black: #010103;
$grey: #1F2833;
$white:#C5C6C7;
$bright:#66FCF1;
$dull:#45A29E;
*{
    background-color: $black;
}
#landingTitle{
    font-family: 'Rock Salt', cursive;
    padding-top: 0;
    margin-top: 0;
    font-size: 3em;
    font-weight: 100;
}

.descContainer{
    width: 60%;
    margin-left: 5%;
}

.description{
    color: $white;
    font-family: 'Rock Salt', cursive;
    text-align: left;
    line-height: 120%;
    font-weight: 600;
    letter-spacing: 0.2em;
}

.root{
  flex-grow: 1;
  width: 100vw;
  padding-top: 5%;
}

@media screen and (max-width: 600px) {
    


}

.item{
    cursor: pointer;
}

// font-family: 'Caveat Brush', cursive;
// font-family: 'Rock Salt', cursive;
// font-family: 'Monoton', cursive;
// font-family: 'Gloria Hallelujah', cursive;
// font-family: 'Permanent Marker', cursive;
// font-family: 'Lobster', cursive;