$black: #010103;
$grey: #1F2833;
$white:#C5C6C7;
$bright:#66FCF1;
$dull:#45A29E;
menu-wrap{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
}

.toggler{
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	width: 60px;
	height: 60px;
	z-index: 3;
	opacity: 0;
	cursor: pointer;
}

.hamburger{
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	width: 60px;
	height: 60px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #111;
}

.hamburger>div{
	position: absolute;
	width: 60%;
	height: 3px;
	border-radius: 1.5px;
	background-color:$bright;
	transition: .4s;
}

.hamburger>div:before{
	content: '';
	position: absolute;
	width: 100%;
	height: 3px;
	border-radius: 1.5px;
	background-color: $bright;
	top: -10px;
	left: 0;
	transition: .4s;
}

.hamburger>div:after{
	content: '';
	position: absolute;
	width: 100%;
	height: 3px;
	border-radius: 1.5px;
	background-color: $bright;
	top: 10px;
	left: 0;
	transition: .4s;
}

.toggler:checked + .hamburger>div{
	transform: rotate(135deg);
}

.toggler:checked:hover + .hamburger>div{
	transform: rotate(225deg);
}

.toggler:checked + .hamburger>div:before, .toggler:checked + .hamburger>div:after{
	top: 0;
	transform: rotate(90deg);
}


.menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	transition: .6s;
}


@media screen and (max-width: 600px) {
    .menu{
		width: 300px;
		height: 700px;
	}
	.menu > div{
		width: 400px;
		height: 900;
	}
  }

.menu > div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-150%) translateY(-50%);
    width: 1600px;
    height: 1600px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: .6s;
}

.menu > div > div{
	max-width: 90vw;
	max-height: 100vh;
	opacity: 0;
	transition: .6s;
}

.menu > div > div > ul > li{
	list-style: none;
	font-size: 3em;
	font-family: 'Work Sans', sans-serif;
	color: $bright;
	font-weight: 100;
	text-decoration: none;
}




.toggler:checked ~ .menu{
	visibility: visible;
}

.toggler:checked ~ .menu > div{
	transform: translateX(-50%) translateY(-50%);
}

.toggler:checked ~ .menu > div > div{
	opacity: 1;
}

.nav-element {
	color: aliceblue;
}



#link{
	 text-decoration:none;
	  font-size:1em;
	   font-weight:100;
		cursor:pointer;
}
